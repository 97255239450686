.contact-form-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form-container h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .contact-form-container p {
    font-size: 1rem;
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .form-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2rem;
    padding-bottom:20px;

  }
  
  .form-group {
    flex: 1;
    margin-bottom: 1rem;
    min-width: 45%;
    position: relative;
  }
  
  .form-group + .form-group {
    margin-left: 1rem;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 0.75rem 0; /* Increased padding to prevent label overlap */
    border: none;
    border-bottom: 2px solid #ccc;
    font-size: 1rem;
    outline: none;
    background-color: transparent;
    position: relative;
    z-index: 1;
  }
  
  .form-group input:focus,
  .form-group textarea:focus,
  .form-group input:not(:placeholder-shown),
  .form-group textarea:not(:placeholder-shown) {
    border-bottom-color: #0f0f0f;
  }
  
  .form-group label {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    color: #ccc;
    transition: all 0.2s ease;
    pointer-events: none;
    z-index: 0;
  }
  
  .form-group input:focus + label,
  .form-group input:not(:placeholder-shown) + label,
  .form-group textarea:focus + label,
  .form-group textarea:not(:placeholder-shown) + label,
  .form-group label.filled {
    top: -10px;
    font-size: 0.75rem;
    color: #0f0f0f;
  }
  
  .form-group .error {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.5rem;
  }
  
  .contact-submit-button {
    padding: 1rem 2rem;
    background-color: #f94300;
    color: #fff;
    border: none;
    border-radius: 24px;
    cursor: pointer;
    font-size: 1rem;
    text-align: center; 
    max-width: 200px;
    margin: 2rem auto;
    position: relative;
    min-width: 120px;
    min-height: 40px;
  }
  
  .contact-submit-button:hover {
    background-color: #d93800;
  }
  
  .loader {
    width: 20px;
    height: 20px;
    border: 2px solid #ffffff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .success-message {
    background-color: #d4edda;
    color: #155724;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .error-message {
    color: #721c24;
    background-color: #f8d7da;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  @media (max-width: 600px) {
    .form-row {
      flex-direction: column;
    }
  
    .form-group {
      min-width: 100%;
      margin-bottom: 2em;
    }
  
    .form-group + .form-group {
      margin-left: 0;
    }
  
    .contact-submit-button {
      width: 100%;
    }
  }
