.industry-accordion {
    max-width: 90%;
    margin: auto; 
    align-items: center;
    justify-content: center;
    margin-top:5em;
    padding-bottom:4em;
  background: url('https://www.pexels.com/photo/blue-and-white-abstract-painting-1020315/') no-repeat center center;
  background-size: cover;
  }
  
  .industry-accordion .accordion__button:hover {
    background-color: #e0e0e0;
  }
  
  .industry-accordion .accordion__panel {
    padding: 1rem;
    background-color: #fff;
    border: 1px solid #ddd;
    border-top: none;
    box-sizing: border-box;
  }
  
  @media (max-width: 600px) {
    .industry-accordion .accordion__button {
      font-size: 0.875rem;
      padding: 0.75rem;
    }
  
    .industry-accordion .accordion__panel {
      padding: 0.75rem;
    }
  }
  
  .industry-accordion .accordion__button {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    padding: 1rem;
    font-size: 1rem;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
  }
  
  .industry-accordion .accordion__button .fa-icon {
    margin-right: 10px;
  }
  
  .industry-accordion .accordion__panel {
    padding: 1rem;
    background-color: #fff;
    border: 1px solid #ddd;
    border-top: none;
    box-sizing: border-box;
  }
  
  .padding-5{
    padding: 5px; color:#f93400;
  }

.opacity_one {
  opacity: 1 !important;
  padding:1em;
  background-color: #fff;;
}

.accordion__heading{
  opacity: 0.8;
}