.new-contact-us-container {
    padding: 40px 20px; 
    text-align: center;
  }
  
  .contact-us-heading {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: #333;
    margin-top:100px;
    padding-bottom: 50px;
  }
  
  .contact-us-subheading {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 40px;
  }
  
  .new-contact-options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .form-section,
  .address-section {
    flex: 1;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
 height: 80%;
    max-width: 600px;
  }

  .aection {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .address-section {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly; 
  }
  
  .office-location {
    margin-bottom: 20px;
    padding-top: 1em;
  }
  
  .office-location h3 {
    margin-bottom: 10px;
    color: #333;
  }
  
  .office-location p {
    margin: 5px 0;
    color: #555;
  }
  
  @media (max-width: 768px) {
    .new-contact-options {
      flex-direction: column;
      gap: 20px;
    }
  
    .form-section,
    .address-section {
      max-width: 100%;
    }
  }
