 
.footer {
    background-color: #f0f2f4;
    color:#0f0f0f;
    padding: 40px 20px; 
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    padding-left:0px;
}

.footer-container { 
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.footer-section {
    flex: 1;
    margin: 0 1rem;
    flex: 1 1 220px;
    margin: 10px;
}

.footer-section h3 {
    font-size: 1.5em;
    margin-bottom: 20px;
    color:#f93400;
    text-align: left;
    padding-left: 32%;
}

.footer-section p, .footer-section ul, .footer-section form {
    margin: 0;
    padding: 0;
}

.footer-section ul {
    list-style: none;
    padding-left: 32%;
    text-align: left;
}

.footer-section ul li {
    margin: 10px 0;
}

.footer-section ul li a {
    color: #0f0f0f;
    text-decoration: none;
    transition: color 0.3s;
}

.footer-section ul li a:hover, .footer-section a:hover {
    color: rgba(51, 51, 51,.6) !important;
}

.social-icons {
    display: flex;
    gap: 10px;
    justify-content: start;
    padding-left: 32%;
}

.social-icons img {
    width: 40px;
    height: 40px;
    transition: transform 0.3s;
}

.social-icons img:hover {
    transform: scale(1.1);
}

.footer-list {
    padding-left: 0;
    list-style-type: none;
}

.footer-list li {
    margin-bottom: 0.5rem;
}

.social-link {
    color: #0f0f0f;
    text-decoration: none;
    font-size: 2rem;
}

.footer-logo {
    width: 120px;
    height: auto;
    margin-bottom: 0.5rem;
}

.footer-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border-top: 1px solid #e0e0e0;
    margin-top: 2rem;
    text-align: center;
}

.footer-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-copyright {
    margin: 0;
    font-size: 0.9em;
    margin-top: 0.5rem;
}

.address-item {
    padding-left: 32%;
    text-align: left;
}
/* Responsive Design */
@media (max-width: 768px) {
    .footer-container {
        grid-template-columns: 1fr 1fr;
    }
    
    .footer-section {
        flex: 1 1 100%;
        width: 100%;
        max-width: none;
        text-align: center;
    }
    
    .footer-section h3,
    .footer-section ul {
        padding-left: 25%;
    } 
    .address-item{
        padding-left: 25%;
    }
 
    .social-icons{
        padding-left: 25%;
    }
}

@media (max-width: 480px) {
    .footer-container {
        grid-template-columns: 1fr;
    }
}

