/* styles.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

 

.details-container {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    overflow: hidden;
}

.details-header {
    background: url('https://images.pexels.com/photos/3184298/pexels-photo-3184298.jpeg') no-repeat center center;
    background-size: cover;
    color: #fff;
    padding: 60px 20px;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.details-header h1 {
    margin: 0;
    font-size: 3em;
    font-weight: 700;
    position: relative;
    z-index: 2;
    animation: details-fadeInDown 1s ease-out;
}

.details-header::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.details-content {
    background: #fff;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: -40px;
    position: relative;
    z-index: 10;
    animation: details-fadeInUp 1s ease-out;
    text-align: justify;
}

.details h2, .details h3 {
    color: #0056b3;
    font-weight: 700;
}

.details p {
    line-height: 1.6;
}

@keyframes details-fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes details-fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
