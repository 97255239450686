.company-profile-container {
    display: flex;
    align-items: center;
    padding: 2rem;
    padding-right: 0;
    background-color: #fff;
    padding-top: 0px;
    margin-right: -5px;
    height: 100vh;
}

.company-profile-text {
    flex: 1;
   
}

.company-profile-text h1 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.company-profile-text h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.company-profile-text p {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    text-align: justify;
}

.company-profile-image {
    flex: 1;
    display: flex;
    justify-content: right;
    height: 100%;
    align-items: center;
}

.company-profile-image img {
    max-width: 90%;
    height: 100%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .company-profile-container {
        flex-direction: column;
        padding: 1rem;
        justify-content: center;
        align-items: center;
        margin-top: 3em;
    }

    .company-profile-text {
        padding-right: 0;
        padding-bottom: 1rem;
    }

    .company-profile-text h1 {
        font-size: 1.5rem;
    }

    .company-profile-text h2 {
        font-size: 1.25rem;
    }

    .company-profile-text p {
        font-size: 0.875rem;
    }
}

.animate-in {
    animation: fadeInUp 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) forwards;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Stagger the animations */
.stat-item:nth-child(1) { animation-delay: 0.1s; }
.stat-item:nth-child(2) { animation-delay: 0.2s; }
.stat-item:nth-child(3) { animation-delay: 0.3s; }
.stat-item:nth-child(4) { animation-delay: 0.4s; }

.stat-item {
    opacity: 0;
    transform: translateY(20px);
}